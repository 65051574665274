import "../style/Modal.css";

const Modal = (props) => {
  return (
    
    <>
      <div className="modal-container">
        <div className="container-infomodal">
          <div className="menu-close" onClick={() => props.toggleModal()}>
            <span className="material-symbols-rounded">close</span>
          </div>
          <div className="nome-prodotto">{props.datiModal.card.nome}</div>
          <div className="dati-prodotto">
            <div className="img-prodotto">
              <img
               src={props.datiModal.card.path}
              />
            </div>
            <div className="ingredienti-modal">
              
              {props.datiModal.card.ingredienti_it}
            </div>
            <div className="ingredienti-modal eng">
              {props.datiModal.card.ingredienti_en}
            </div>
          </div>
         
        </div> 
      </div>
    </>
  );
};
export default Modal;
