import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../assets/logo_menu_manager.png"; // Importa l'immagine del logo
import { useDispatch } from "react-redux";
import { scrollAction } from "../store/login/login.action";

export default function CustomNavbar() {
  const dispatch = useDispatch();

  const [isScrolled, setIsScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false); // Stato per controllare se il menu è espanso

  const navigate = useNavigate();

  const handleBlogClick = () => {
    const scroller = {
      scrol: "scroll",
      top: false,
    };
    dispatch(scrollAction(scroller));

    console.log("Handle Blog Click", scroller);
    navigate("/blog");
  };
  const handleHomeClick = () => {
    const scroller = {
      scrol: "scroll",
      top: true,
    };
    dispatch(scrollAction(scroller));

    console.log("Handle Blog Click", scroller);
    navigate("/blog");
  };
  const handleContgattiClick = () => {
    const scroller = {
      scrol: "scroll",
      top: false,
    };
    dispatch(scrollAction(scroller));

    console.log("Handle Blog Click", scroller);
    navigate("/contatti");
  };
  const handleAboutUSClick = () => {
    const scroller = {
      scrol: "scroll",
      top: false,
    };
    dispatch(scrollAction(scroller));

    console.log("Handle Blog Click", scroller);
    navigate("/contatti");
  };const handleMenuClick = () => {
    const scroller = {
      scrol: "scroll",
      top: false,
    };
    dispatch(scrollAction(scroller));

    console.log("Handle Blog Click", scroller);
    navigate("/menu");
  };
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar expand="lg" fixed="top"  expanded={expanded} style={{ backgroundColor: "#007bff" }}>
      <Navbar.Brand href="/">
        <img src={logo} alt="Logo" style={{ height: "40px" }} />
      </Navbar.Brand>
     <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(!expanded)}
        // Inverti lo stato dell'espansione
      />
      <Navbar.Collapse  id="basic-navbar-nav" >
        <Nav className="ml-auto align-items-center" >
          <NavLink className="nav-link" to="/" onClick={handleHomeClick}>
            Home
          </NavLink>
          <NavLink className="nav-link" to="/" onClick={handleAboutUSClick}>
            Chi Siamo
          </NavLink>
          <NavLink className="nav-link" to="/blog" onClick={handleBlogClick}>
            Blog
          </NavLink>
          <NavLink className="nav-link" to="/menu" onClick={handleMenuClick}>
            Menu
          </NavLink>
          <NavLink
            className="nav-link"
            to="/contatti"
            onClick={handleContgattiClick}
          >
            Contatti
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
