import { LOGIN_INITIAL_STATE } from "./login.state";
import { LOGIN_ACTIONS_TYPES } from "./login.types";

export const loginReducer = (state = LOGIN_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_ACTIONS_TYPES.LOGIN:
      return {
        ...state,
        isLogin: true,
        user: {
          id: payload.id,
          ruolo: payload.ruolo,
          nome: payload.nome,
        },
      };
    case LOGIN_ACTIONS_TYPES.LOGOUT:
      return {
        ...state,
      };
    case LOGIN_ACTIONS_TYPES.LOGIN_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case LOGIN_ACTIONS_TYPES.SCROLL:
      return {
        ...state,
        scroller: { scrol: payload.scrol, top: payload.top },
      };
    default: {
      return state;
    }
  }
};
