// Home.js

import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import BlogItem from "../components/BlogItem";
import blogData from "../fake/blogData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      swipeToSlide: true,
     
     
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

  return (
    <div  id="section">
      <Container className="mt-5">
        <Row className="mt-5">
          <Col>
            <h2>About Us</h2>
            <p>
              Benvenuti alla Pizzeria del Gusto, il luogo ideale per gli amanti
              della pizza autentica e del buon cibo. Con anni di esperienza
              nella preparazione delle pizze più deliziose, siamo orgogliosi di
              offrire un menu diversificato e servizi di alta qualità per
              soddisfare ogni palato. La nostra missione è quella di creare
              un'esperienza culinaria unica e indimenticabile per i nostri
              clienti. Venite a trovarci e scoprite il piacere di gustare
              autentiche pizze italiane e altre specialità, tutto preparato con
              amore e passione.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="https://via.placeholder.com/150" />
              <Card.Body>
                <Card.Title>Servizio di Consegna</Card.Title>
                <Card.Text>
                  Forniamo il servizio di consegna a domicilio per gustare le
                  nostre pizze comodamente a casa tua.
                </Card.Text>
                <Button variant="primary">Scopri di più</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="https://via.placeholder.com/150" />
              <Card.Body>
                <Card.Title>Menu Diversificato</Card.Title>
                <Card.Text>
                  Scegli tra una varietà di pizze, antipasti, bevande e dessert
                  nel nostro menu.
                </Card.Text>
                <Button variant="primary">Esplora il menu</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="https://via.placeholder.com/150" />
              <Card.Body>
                <Card.Title>Ambiente Accogliente</Card.Title>
                <Card.Text>
                  Vieni a trovarci nel nostro ristorante e goditi un'atmosfera
                  accogliente e conviviale.
                </Card.Text>
                <Button variant="primary">Scopri di più</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="latest-blogs">
        <h2>Ultimi Blog</h2>
        <Slider {...settings}>
          {blogData.map((blog) => (
            <div className="blog-item-wrapper" key={blog.id}>
              <BlogItem blog={blog} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Home;
