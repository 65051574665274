import React, { useState } from "react";
import "../style/MySelect.css";

const SelectCategorieIngredienti = ({
  value,
  onValue,
  categorieall,
  valueName,
  
}) => {
  return (
    <div className="select">
      <select
        className="select"
        aria-label="Default select example"
        value={value}
        key={value}
        onChange={(e) => onValue(e.target.value)}
      >
        <option key={value} value={value}>{valueName}</option>
        {categorieall &&
          categorieall.map((categoria) => (
            <option
              key={categoria.id_categorie_ingredienti}
              value={categoria.id_categorie_ingredienti}
            >
              {categoria.nome}
            </option>
          ))}
      </select>
    </div>
  );
};
export default SelectCategorieIngredienti;
