import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Layout from "./pages/Layout";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Contatti from "./pages/Contatti";
import Products from "./pages/Products";

import SingleBlog from "./pages/SingleBlog";
import { Provider } from "react-redux";
import { store } from "./store/store";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />}exact />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contatti" element={<Contatti />} />
            <Route path="/menu" element={<Products />} />
            <Route path="/blog/:id" element={<SingleBlog />} /> {/* Aggiungi questa linea */}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
