import { PRODOTTI_INITIAL_STATE } from "./prodotti.state";
import { PRODOTTI_ACTIONS_TYPES } from "./prodotti.types";

export const prodottiReducer = (state = PRODOTTI_INITIAL_STATE, action) => {
  const { type, payload } = action;


  switch (type) {
    case PRODOTTI_ACTIONS_TYPES.SET_CATEGORIE:
      return {
        ...state,
        categorie: payload,

     
      };
    case PRODOTTI_ACTIONS_TYPES.SET_OFFERTE_BY_CATEGORIA:
      return {
        ...state,
        prodotti: payload,

      };
    case PRODOTTI_ACTIONS_TYPES.SET_PRODOTTI_BY_CATEGORIA:
      return {
        ...state,
        prodotti: payload[0].prodottis,
        
      };
      case PRODOTTI_ACTIONS_TYPES.PRODOTTI_LOADING:
        return {
          ...state,
          loading: payload,
        };
    default: {
      return state;
    }
  }
};
