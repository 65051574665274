export const LOGIN_INITIAL_STATE = {
  isLogin: false,
  loading: false,
  scroller: { scrol: "", top: false },
  user: {
    id: "",
    ruolo: "",
    nome: "",
  },
};
