import axios from 'axios'


const node =axios.create({
   
    baseURL :""
})

export {node}

