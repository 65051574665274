// Blog.js

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogItem from "../components/BlogItem";
import blogData from "../fake/blogData";

function Blog() {
  return (
    <div id="section">
      <Container className="mt-5">
        <h2>Blog</h2>
        <Row>
          {blogData.map((blog) => (
            <Col md={4} key={blog.id}>
              <BlogItem blog={blog} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Blog;
