import React, { useEffect, useState } from "react";
import Cards from "../components/Cards";
import Modal from "../components/ModalProdotto";
import "../style/Products.css";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../components/Spinner";

import SelectCategorieIngredienti from "../components/SelectCategorieIngredienti";
import logo from "../assets/logo_menu_manager.png";
import {
  getCategorieSito,
  getOffertesitoByCategoria,
  getProdottisitoByCategoria,
  setOfferteByCategoriaAction,
} from "../store/prodotti/prodotti.action";
import CardMenu from "../components/CardMenu";

const Products = () => {
  const dispatch = useDispatch();

  const prodotti = useSelector((state) => state.producs.prodotti);
  const loading = useSelector((state) => state.producs.loading);
  const loading2 = false; //useSelector((state) => state.login.loading);

  const categorie_ingredienti = useSelector((state) => state.producs.categorie);
  const [value, setValue] = useState("");
  const [valueName, setValueName] = useState("Seleziona una categoria");

  const handleValue = async (e) => {
    await setValue(e);
    await setValueName(e);
  };
  let itemList = [];
  useEffect(() => {

    async function fetchportate() {
    await dispatch(setOfferteByCategoriaAction([]));

      if (value != "") {
        if (value == "Offerte") {
          await dispatch(getOffertesitoByCategoria(value));
        } else {
          await dispatch(getProdottisitoByCategoria(value));
        }
      }
    }
    fetchportate();
  }, [value]);

  useEffect(() => {
    async function fetchportate() {
      await dispatch(getCategorieSito());
    }
    fetchportate();
  }, []);
  const [modal, setModal] = useState(false);
  const [datiModal, seDatiModal] = useState({
    card: "",
    onqty: "",

    prezzo: "",
  });
  const toggleModal = async (card, onqty, id_prodotto_interno, prezzo) => {
    setModal(!modal);
    seDatiModal({
      card: card,

      prezzo: prezzo,
    });
  };

  const modalpass = (prodotto, qty, id_prodotto_interno, prezzo) => {
    return <Modal toggleModal={toggleModal} datiModal={datiModal} />;
  };

  const map_card5 = () => {


    itemList = prodotti.map((prodotto, index) => {
      let newProdotto = {
        ...prodotto,
        ingredienti_it: prodotto.composizione_menus.map((cm) => (
          <div className="riga" key={cm.Prodotti_Correlati.id}>
            <div className="tipo_piatto">
              {cm.Prodotti_Correlati.categorie.nome}
            </div>
            <div className="nome_piatto">{cm.Prodotti_Correlati.nome}</div>
            <div className="ing_piatto_it">
              {cm.Prodotti_Correlati.ingredienti_it}
            </div>
            <div className="ing_piatto_en">
              {cm.Prodotti_Correlati.ingredienti_en}
            </div>
          </div>
        )),
      };

      return (
        <CardMenu key={index} toggleModal={toggleModal} card={newProdotto} />
      );
    });

    return itemList;
  };

  const map_card = () => {
   

    itemList = prodotti.map((prodotto, index) => {
      return <Cards key={index} toggleModal={toggleModal} card={prodotto} />;
    });

    return itemList;
  };
  return (
    <div className="coontentprodotti"  id="section">
      <div className="container-select">
        {loading ? (
          <Spinner />
        ) : (
          <SelectCategorieIngredienti
            value={value}
            onValue={handleValue}
            categorieall={categorie_ingredienti}
            valueName={valueName}
          />
        )}
      </div>

      <div className="prodotti">
        {modal ? modalpass() : null}
        {prodotti.length > 0 && !loading && valueName == "Menu Completi"
          ? map_card5()
          : map_card()}
      </div>
    </div>
  );
};

export default Products;
