// BlogItem.js

import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function BlogItem({ blog }) {
  return (
    <div className="cardmargin">
      <Card>
        <Card.Img variant="top" src={blog.image} />
        <Card.Body>
          <Card.Title>{blog.title}</Card.Title>
          <Card.Text>{blog.excerpt}</Card.Text>
          <Link to={`/blog/${blog.id}`}>
            <Button variant="primary">Scopri di più</Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

export default BlogItem;
