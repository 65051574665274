// src/blogData.js

const blogData = [
    {
      id: 1,
      title: "Titolo del Primo Blog",
      excerpt: "Estratto del primo blog...",
      content: "Contenuto del primo blog...",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 2,
      title: "Titolo del Secondo Blog",
      excerpt: "Estratto del secondo blog...",
      content: "Contenuto del secondo blog...",
      image: "https://via.placeholder.com/150",
    }, {
      id: 3,
      title: "Titolo del Secondo Blog",
      excerpt: "Estratto del secondo blog...",
      content: "Contenuto del secondo blog...",
      image: "https://via.placeholder.com/150",
    }, {
      id: 4,
      title: "Titolo del Secondo Blog",
      excerpt: "Estratto del secondo blog...",
      content: "Contenuto del secondo blog...",
      image: "https://via.placeholder.com/150",
    }, {
      id: 5,
      title: "Titolo del Secondo Blog",
      excerpt: "Estratto del secondo blog...",
      content: "Contenuto del secondo blog...",
      image: "https://via.placeholder.com/150",
    },
    // Aggiungi altri blog qui
  ];
  
  export default blogData;
  