import React from "react";
import { Carousel, Container, Row, Col, Button } from "react-bootstrap";
import image1 from "../assets/image1.jpeg";
import image2 from "../assets/image2.jpeg";

function Slider() {
  const imageStyle = {
    maxHeight: "600px",
    width: "100%",
    objectFit: "cover",
  };

  const sliderStyle = {
    marginTop: "50px", // Altezza della navbar + margine aggiuntivo (se necessario)
  };

  return (
    <div style={sliderStyle}>
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image1}
          alt="First slide"
          style={imageStyle}
        />
        <Carousel.Caption className="custom-caption">
          <Container>
            <Row className="sinistra">
              <Col md={3}>
                <h3>Benvenuti alla Pizzeria del Gusto</h3>
                <p>Scopri le nostre deliziose pizze preparate con amore.</p>
                <Button variant="primary">Ordina ora</Button>
              </Col>
            </Row>
          </Container>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image2}
          alt="Second slide"
          style={imageStyle}
        />
        <Carousel.Caption className="custom-caption left">
          <Container>
            <Row className="destra">
              <Col md={3}>
                <h3>Esperienza unica di gusto</h3>
                <p>Assapora la bontà delle nostre specialità di pizza.</p>
                <Button variant="primary">Scopri di più</Button>
              </Col>
            </Row>
          </Container>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default Slider;
