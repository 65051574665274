import React from "react";
import "../style/Cards.css";

const CardMenu = (props) => {
  let prezzo = Number(props.card.prezzo);
  return (
    <div className="mycard">
      <a
        href="#fakeLink"
        onClick={() =>
          props.toggleModal(
            props.card,
            props.onqty,
            props.id_prodotto_interno,
            props.card.prezzo
          )
        }
      >
        <div className="titolo">{props.card.nome}</div>
        <img className="image" src={props.card.path} alt="BigCo Inc. logo" />
      </a>

      <div className="conenitore-info">
        <div className="prezzo">Prezzo {Number(prezzo).toFixed(2)} €</div>
      </div>
    </div>
  );
};

export default CardMenu;
