import { combineReducers } from "redux";

import {loginReducer} from "./login/login.reducer";
import { prodottiReducer } from "./prodotti/prodotti.reducer";


export const rootReducer = combineReducers({
  login:loginReducer,
  producs:prodottiReducer
});

