import React, { useState } from "react";
import "../style/Cards.css";

const Cards = (props) => {
  let prezzo =
    props.card.prezzo_offerte != 0.0 && props.card.prezzo_offerte !== undefined
      ? Number(props.card.prezzo_offerte)
      : Number(props.card.prezzo);
  const [colore, setColore] = useState( props.card.prezzo_offerte != 0.0 && props.card.prezzo_offerte !== undefined ? "red" : "black");
  return (
    <div className="mycard">
      <a
        href="#fakeLink"
        onClick={() =>
          props.toggleModal(
            props.card,
            props.onqty,
            props.id_prodotto_interno,
            prezzo
          )
        }
      >
        <div className="titolo">{props.card.nome}</div>
        <img className="image" src={props.card.path} alt="BigCo Inc. logo" />
      </a>
      <div className="conenitore-info">
      <div
          className="prezzo"
          style={{
            color: colore 
          }}
        >
          {" "}
          {Number(props.card.prezzo).toFixed(2)} €
        </div>

        {props.card.prezzo_offerte != 0.0 &&
        props.card.prezzo_offerte !== undefined ? (
          <div className="prezzo">
            {" "}
            {Number(props.card.prezzo_offerte).toFixed(2)} €
          </div>
        ) : null}
       
      </div>
    </div>
  );
};

export default Cards;
