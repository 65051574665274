// SingleBlog.js

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import blogData from "../fake/blogData"; // Importa la lista di blog

function SingleBlog() {
  const { id } = useParams(); // Ottieni l'id dall'URL

  // Trova l'articolo del blog corrispondente all'id
  const blog = blogData.find((blog) => blog.id == id);

  if (!blog) {
    return <p>Articolo non trovato.</p>;
  }

  return (
    <div>
      <Container className="mt-5">
        <Row>
          <Col>
            <h2>{blog.title}</h2>
            <p>{blog.content}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SingleBlog;
