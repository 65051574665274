// Layout.js

import React, { useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Slider from "../components/Slider";
import { useDispatch, useSelector } from "react-redux";
import { scrollAction } from "../store/login/login.action";
import Footer from "../components/Footer";

const Layout = (props) => {
  const scroller = useSelector((state) => state.login.scroller);
  const blogSectionRef = useRef(null);
  const sliderRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (scroller.scrol === "scroll") {


      dispatch(scrollAction({ scrol: "", top: false }));
      const sliderRefHeight = sliderRef.current.clientHeight;
     

      scroller.top ?   window.scrollTo(0, 0) :window.scrollTo(0, sliderRefHeight);

    }
  }, [scroller]);

  return (
    <div className="container-layout">
      <div className="header">
        <Navbar />
      </div>
      <div className="slider" ref={sliderRef}>
        <Slider />
      </div>

      <div id="section" ref={blogSectionRef}>
        <Outlet />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
