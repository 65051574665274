import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="bg-dark text-light mt-5 p-3">
       <div className="mt-5">
      <Container>
        <Row>
          <Col md={6}>
            <h4>Contatti</h4>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              Via Example, 12345, Città, Italia
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              +39 123 456789
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              info@example.com
            </p>
          </Col>
          <Col md={6}>
            <h4>Orari di Apertura</h4>
            <p>
              Lunedì - Venerdì: 10:00 - 22:00
              <br />
              Sabato - Domenica: 11:00 - 23:00
            </p>
          </Col>
          <div className="social-icons text-center">
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faFacebook} size="2x" color="white" />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faTiktok} size="2x" color="white" />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faYoutube} size="2x" color="white" />
            </a>
          </div>
        </Row>
        <hr />
        <p className="text-center">
          © {new Date().getFullYear()} Pizzeria del Gusto. Tutti i diritti
          riservati.
        </p>
      </Container>
      </div>
    </footer>
  );
}

export default Footer;
