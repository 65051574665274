import { PRODOTTI_ACTIONS_TYPES } from "./prodotti.types";
import { node } from "../../Axios";

export const getCategorieSito = () => async (dispatch) => {
  await dispatch(prodottiLoading(true));

  try {
    const config = {};
    const bodyParameters = {};
    const res = await node.post(
      "/api/categorie/getcategoriesito",
      bodyParameters,
      config
    );


    await dispatch(setCategorieAction(res.data.categorie));
  } catch (error) {
    console.log(error);
    await dispatch(prodottiLoading(false));
  } finally {
    await dispatch(prodottiLoading(false));
  }
};

export const getOffertesitoByCategoria = (id) => async (dispatch) => {
  await dispatch(prodottiLoading(true));

  try {
    const config = {};
    const bodyParameters = {
      nome: id,
    };
    const res = await node.post(
      "/api/prodotti/getoffertesitobycategoria",
      bodyParameters,
      config
    );

    await dispatch(setOfferteByCategoriaAction(res.data.prodotti));
  } catch (error) {
    await dispatch(prodottiLoading(false));
  } finally {
    await dispatch(prodottiLoading(false));
  }
};

export const getProdottisitoByCategoria = (id)=> async (dispatch) => {
  await dispatch(prodottiLoading(true));

   
    try {
     
      const config = {
      };
      const bodyParameters = {
        nome: id,
      };
      const res = await node.post(
        "/api/prodotti/getprodottisitobycategoria",
        bodyParameters,
        config
      );
      await dispatch(setProdottibyCategoriaAction(res.data.prodotti));
    } catch (error) {
      await dispatch(prodottiLoading(false));

    }finally {
      await dispatch(prodottiLoading(false));
    }
  };

export const createAction = (type, payload) => ({ type, payload });

export const setCategorieAction = (result) =>
  createAction(PRODOTTI_ACTIONS_TYPES.SET_CATEGORIE, result);

export const setOfferteByCategoriaAction = (result) =>
  createAction(PRODOTTI_ACTIONS_TYPES.SET_OFFERTE_BY_CATEGORIA, result);

export const setProdottibyCategoriaAction = (result) =>
  createAction(PRODOTTI_ACTIONS_TYPES.SET_PRODOTTI_BY_CATEGORIA, result);

export const prodottiLoading = (flag) =>
  createAction(PRODOTTI_ACTIONS_TYPES.PRODOTTI_LOADING, flag);
