import { LOGIN_ACTIONS_TYPES } from "./login.types";
import { node } from "../../Axios";

export const loginAsync = (username, password) => async (dispatch) => {
    try {
      // Imposta lo stato di caricamento a true
      dispatch(loginLoading(true));
  
      // Simula la chiamata asincrona
      const userResponse = {
        id: "123",
        ruolo: "utente",
        nome: "Mario Rossi"
      };
  
      // Attendi un po' per simulare il caricamento
      await new Promise((resolve) => setTimeout(resolve, 2000));
  
      // Invia l'azione di login con le informazioni dell'utente
      dispatch(loginAction(userResponse));
    } catch (error) {
      console.error("Errore durante il login:", error);
    } finally {
      // Dopo che la chiamata è terminata, imposta lo stato di caricamento a false
      dispatch(loginLoading(false));
    }
  };

export const createAction = (type, payload) => ({ type, payload });
export const loginAction = (userData) => createAction(LOGIN_ACTIONS_TYPES.LOGIN, userData);
export const scrollAction = (scroller) => createAction(LOGIN_ACTIONS_TYPES.SCROLL, scroller);
export const logoutAction = () => createAction(LOGIN_ACTIONS_TYPES.LOGOUT);
export const loginLoading = () => createAction(LOGIN_ACTIONS_TYPES.LOGIN_LOADING);
