import React, { useState } from "react";

function Contatti() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!name) {
      validationErrors.name = "Inserisci il tuo nome";
    }

    if (!email) {
      validationErrors.email = "Inserisci l'indirizzo email";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Inserisci un indirizzo email valido";
    }

    if (!message) {
      validationErrors.message = "Inserisci il messaggio";
    }

    if (Object.keys(validationErrors).length === 0) {
      // Invia l'email o esegui le azioni necessarie qui
      setSubmitted(true);
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="container mt-5" id="section">
      <div className="row">
        <div className="col-md-6">
          <h2>Invia un messaggio</h2>
          {submitted ? (
            <p>Messaggio inviato con successo!</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nome</label>
                <input
                  type="text"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
              <div className="form-group">
                <label>Messaggio</label>
                <textarea
                  className={`form-control ${errors.message ? "is-invalid" : ""}`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
              </div>
              <button type="submit" className="btn btn-primary">
                Invia
              </button>
            </form>
          )}
        </div>
        <div className="col-md-6">
          <iframe
            title="Mappa"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d49900.89172268628!2d16.157444743536878!3d38.58432122861283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sit!2sit!4v1691858211502!5m2!1sit!2sit"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
         
        </div>
      </div>
    </div>
  );
}

export default Contatti;
